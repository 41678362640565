import BASE_COLORS from './colors';
import { DEFAULT_THEME } from '@zendeskgarden/react-theming';

export const theme = {
  ...DEFAULT_THEME,
  borders: {
    sm: '1px solid',
    md: '3px solid'
  },
  borderRadii: {
    sm: '2px',
    md: '4px'
  },
  borderStyles: {
    solid: 'solid'
  },
  borderWidths: {
    sm: '1px',
    md: '3px'
  },
  breakpoints: {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px'
  },
  colors: {
    base: '#424242',
    background: '#FFFAEB',
    foreground: '#424242',
    primaryHue: '#0E60FF',
    dangerHue: 'red',
    warningHue: 'yellow',
    successHue: 'green',
    neutralHue: '#fafafa',
    chromeHue: 'kale'
  },
  fonts: {
    raleway: `'Raleway', sans-serif`,
    roboto: `'Roboto', sans-serif`,
    system:
      'system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",Arial,sans-serif'
  },
  fontSizes: {
    xxxs: '12px',
    xxs: '14px',
    xs: '16px',
    sm: '20px',
    md: '24px',
    lg: '32px',
    xl: '40px',
    xxl: '52px',
    xxxl: '72px'
  },
  fontWeights: {
    thin: 100,
    extralight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  iconSizes: {
    sm: '12px',
    md: '16px',
    lg: '26px'
  },
  lineHeights: {
    sm: '18px',
    md: '22px',
    lg: '26px',
    xl: '30px',
    xxl: '34px',
    xxxl: '46px'
  },

  shadowWidths: {
    sm: '2px',
    md: '3px'
  },
  shadows: {
    sm: (color: string) => `0 0 0 ${theme.shadowWidths.sm} ${color}`,
    md: (color: string) => `0 0 0 ${theme.shadowWidths.md} ${color}`,
    lg: (offsetY: string, blurRadius: string, color: string) =>
      `0 ${offsetY} ${blurRadius} 0 ${color}`
  },
  space: {
    base: 4,
    xxs: '4px',
    xs: '8px',
    sm: '12px',
    md: '20px',
    lg: '32px',
    xl: '40px',
    xxl: '48px'
  }
};
