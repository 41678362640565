import React from 'react';
import styled from 'styled-components';

type P1PropsType = {
  children: JSX.Element | JSX.Element[] | string[] | string;
  className?: string;
  theme?: {};
};

const StyledP1 = styled.p`
  font-family: ${(props) => props.theme.fonts.roboto};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.sm};
`;

export const P1 = (props: P1PropsType) => {
  const { className, children } = props;
  return <StyledP1 className={className}>{children}</StyledP1>;
};
