import React from 'react';
import styled from 'styled-components';

type H4PropsType = {
  children: JSX.Element | JSX.Element[] | string[] | string;
  theme?: {};
};

const StyledH4 = styled.h4`
  font-family: ${(props) => props.theme.fonts.raleway};
  font-weight: ${(props) => props.theme.fontWeights.extrabold};
  font-size: ${(props) => props.theme.fontSizes.md};
  margin: 0;
`;

export const H4 = (props: H4PropsType) => {
  const { children } = props;
  return <StyledH4>{children}</StyledH4>;
};
