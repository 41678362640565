import React from 'react';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';

import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import styled from 'styled-components';
import { H2 } from '../components/H2';
import { Button } from '../components/Button';
import { P2 } from '../components/P2';

const StyledGrid = styled(Grid)`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 50px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 20px;
    margin-top: 50px;
  }

  margin-bottom: 50px;
`;

const StyledImage = styled.img`
  display: block;
  margin: 0 auto;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const StyledH2 = styled(H2)`
  text-align: center;
`;

const StyledP2 = styled(P2)`
  font-size: 20px;
  max-width: 600px;
  margin: 10px auto 30px auto;
  text-align: center;
`;

const StyledDownloadButton = styled(Button)`
  width: 100%;
  margin: 0 auto;
  border: 5px solid #424242;
  color: #ffffff;
  background-color: #424242;
  &:hover {
    background-color: #424242;
    border: 5px solid #424242;
    color: #ffffff;
  }
`;

const StyledButton = styled(Button)`
  margin: 20px auto 0 auto;
  border: 5px solid #424242;
  color: #424242;
  width: 100%;

  &:hover {
    background-color: transparent;
    border: 5px solid #424242;
    color: #424242;
  }
`;

const StyledLinkOne = styled(ReactGA.OutboundLink)`
  text-decoration: none;
  color: #ffffff;
`;
const StyledLinkTwo = styled(ReactGA.OutboundLink)`
  text-decoration: none;
  color: #424242;
`;

const thisIsWeird = {
  image:
    'https://storage.googleapis.com/kubby-app/production/This_is_Weird_COVER-Final.jpg',
  title: 'This Is Weird',
  description: (
    <span>
      Thank you for making this possible. Click to download your advance copy of{' '}
      <b>“This Is Weird”</b>.
    </span>
  ),
  link: 'https://storage.googleapis.com/kubby-app/production/THIS_IS_WEIRD.zip'
};

const PublicProfile: React.FC = (props: any) => {
  const { match, history } = props;

  if (match.params.name !== 'thisisweird') {
    history.push(`/`);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${thisIsWeird.title} - Kubby`}</title>
      </Helmet>
      <StyledGrid columns={'12'}>
        <Row justifyContent={'center'}>
          <Col md={6} lg={4}>
            <StyledH2>{thisIsWeird.title}</StyledH2>
            <StyledP2>{thisIsWeird.description}</StyledP2>
            <ReactGA.OutboundLink
              eventLabel="DOWLOAD_ALBUM:IMAGE_CLICKED"
              to={thisIsWeird.link}
              target="_blank"
            >
              <StyledImage src={thisIsWeird.image} alt={thisIsWeird.title} />
            </ReactGA.OutboundLink>

            <br />
            <br />
            <StyledLinkOne
              eventLabel="DOWLOAD_ALBUM:BUTTON_CLICKED"
              to={thisIsWeird.link}
              target="_blank"
            >
              <StyledDownloadButton>Download Now</StyledDownloadButton>
            </StyledLinkOne>
            <StyledLinkTwo
              eventLabel="SAVE_ALBUM:BUTTON_CLICKED"
              to="https://ffm.to/thisisweird"
              target="_blank"
            >
              <StyledButton>Pre-Save</StyledButton>
            </StyledLinkTwo>
          </Col>
        </Row>
      </StyledGrid>
    </>
  );
};

export default PublicProfile;
