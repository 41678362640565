import React from 'react';
import styled from 'styled-components';

type H1PropsType = {
  children: JSX.Element | JSX.Element[] | string[] | string;
  className?: string;
  theme?: {};
};

const StyledH1 = styled.h1`
  font-family: ${(props) => props.theme.fonts.raleway};
  font-weight: ${(props) => props.theme.fontWeights.extrabold};
  font-size: ${(props) => props.theme.fontSizes.xxl};
  margin: 0;
`;

export const H1 = (props: H1PropsType) => {
  const { className, children } = props;

  return <StyledH1 className={className}>{children}</StyledH1>;
};
