import React from 'react';
import styled from 'styled-components';

type H2PropsType = {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
  theme?: {};
};

const StyledH2 = styled.h2`
  font-family: ${(props) => props.theme.fonts.raleway};
  font-weight: ${(props) => props.theme.fontWeights.extrabold};
  font-size: ${(props) => props.theme.fontSizes.xl};
  margin: 0;
`;

export const H2 = (props: H2PropsType) => {
  const { className, children } = props;
  return <StyledH2 className={className}>{children}</StyledH2>;
};
