import React from 'react';
import styled from 'styled-components';

type P2PropsType = {
  children: JSX.Element | JSX.Element[] | string[] | string;
  className?: string;
  theme?: {};
  style?: {};
};

const StyledP2 = styled.p`
  font-family: ${(props) => props.theme.fonts.roboto};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.sm};
`;

export const P2 = (props: P2PropsType) => {
  const { className, children, style } = props;
  return (
    <StyledP2 className={className} style={style}>
      {children}
    </StyledP2>
  );
};
