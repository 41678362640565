import React, { useContext } from 'react';
import { Redirect, useLocation, Route, Switch } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';

import {
  Header,
  Main,
  Footer,
  HeaderItemText,
  HeaderItemWrapper
} from '@zendeskgarden/react-chrome';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

// import PhoneNumber from './pages/PhoneNumber';
// import PaymentMethod from './pages/PaymentMethod';
// import CreateOrganization from './pages/CreateOrganization';
// import SignUp from './pages/Signup';
// import Login from './pages/Login';
// import Profile from './pages/Profile';
// import Links from './pages/Links';

import PublicProfile from './pages/PublicProfile';
import Home from './pages/Home';
import RequestAccess from './pages/RequestAccess';
import { featureFlags } from './utils/features';
import { P2 } from './components/P2';

import KubbyMobileDump from './assets/home/kubby_dump_mobile.svg';
import KubbyLogo from './assets/Logo.svg';

const StyledMain = styled(Main)`
  overflow: hidden;
`;

const StyledFooter = styled(Footer)`
  text-align: center;
  justify-content: center;
  align-content: center;
  border-top: none;
`;

const StyledHeader = styled(Header)`
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: none;
  z-index: 3;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 50px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 50px 20px;
    flex-direction: column;
    align-items: end;
  }
`;

const StyledHeaderItemText = styled(HeaderItemText)`
  margin: 0 0 0 25px;
  z-index: 2;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 25px 0 0 0;
  }
`;

const LogoLink = styled(Link)`
  z-index: 3;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  border-bottom: 5px solid ${(props) => props.theme.colors.primaryHue};
  font-family: ${(props) => props.theme.fonts.roboto};
  padding-bottom: 3px;
  color: black;
  z-index: 2;
`;

const Container = styled.div`
  padding: 60px 0 0 0;
`;

const ImgWrapper = styled.span`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: inherit;
  }
`;

const StyledKubbyMobileDumpImg = styled.img`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 375px;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
`;

// if (process.env.NODE_ENV === 'production') {
//   setLogger(null);
// }

const PrivateRoute = ({ component, isAuthenticated, ...rest }: any) => {
  const routeComponent = (props: any) =>
    isAuthenticated ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    );
  return <Route {...rest} render={routeComponent} />;
};

const Routes: React.FC = (props: any) => {
  const location = useLocation();
  const dt = new Date();
  const year: number = dt.getFullYear();

  return (
    <>
      <StyledHeader>
        <LogoLink to="/">
          <img src={KubbyLogo} alt="KubbyLogo" />
        </LogoLink>
        {featureFlags.requestAccess ? (
          <>
            <StyledHeaderItemText>
              {' '}
              <StyledLink to={'/request-access'}>Request Access</StyledLink>
            </StyledHeaderItemText>
          </>
        ) : (
          <HeaderItemWrapper>
            <StyledHeaderItemText>FAQ</StyledHeaderItemText>
            <StyledHeaderItemText>Pricing</StyledHeaderItemText>
            <StyledHeaderItemText>
              {' '}
              <StyledLink to={'/login'}>Log In</StyledLink>
            </StyledHeaderItemText>
          </HeaderItemWrapper>
        )}
        {location.pathname !== '/thisisweird' ? (
          <ImgWrapper>
            <StyledKubbyMobileDumpImg src={KubbyMobileDump} alt="Kubby Dump" />
          </ImgWrapper>
        ) : null}
      </StyledHeader>
      <StyledMain>
        <Container>
          <Switch>
            {/* <Route exact path="/phone-number" component={PhoneNumber} />
            <Route exact path="/payment-method" component={PaymentMethod} />
            <Route exact path="/create-organization" component={CreateOrganization} />
            <Route exact path="/sign-up" component={SignUp} /> */}
            <Route exact path="/request-access" component={RequestAccess} />
            {/* <Route exact path="/login" component={Login} /> */}
            <Route exact path="/:name" component={PublicProfile} />
            <Route exact path="/" component={Home} />
            {/* <PrivateRoute
              path="/profile"
              isAuthenticated={isAuthenticated}
              component={Profile}
            />
            <LinkProvider>
              <PrivateRoute
                path="/links"
                isAuthenticated={isAuthenticated}
                component={Links}
              />
            </LinkProvider> */}
          </Switch>
        </Container>
      </StyledMain>
      <StyledFooter>
        <P2>© {`${year}`} Kubby</P2>
      </StyledFooter>
    </>
  );
};

export default Routes;
