import React from 'react';
import styled from 'styled-components';

interface InputFieldStyleProps {
  style: InputStyle;
  theme?: any;
}

interface InputStyle {
  width?: string;
  borderBottom?: string;
  borderRadius?: string;
}

const StyledInput = styled.input`
  border: none;
  font-size: 16px;
  height: auto;
  padding: 15px;
  width: ${(props: InputFieldStyleProps) => props.style.width || '100%'};
  background-color: #ffffff;
  border-radius: ${(props: InputFieldStyleProps) => props.style.borderRadius || '5px'};
  border-bottom: ${(props: InputFieldStyleProps) =>
    props.style.borderBottom || '2px solid #e5e5e4'};
  color: #8a97a0;
  font-family: ${(props: InputFieldStyleProps) => props.theme.fonts.roboto};
  margin-bottom: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 90%;
  }
`;

interface TextInputType {
  className?: string;
  id: string;
  name: string;
  defaultValue?: string;
  placeholder?: string;
  type: 'email' | 'text' | 'number' | 'phone' | 'password';
  forwardRef?: any;
  ariaInvalid?: boolean;
  ariaDescribedBy?: string;
  style?: {};
  borderBottom?: string;
  borderRadius?: string;
  onChange?: () => void;
}

export const InputField = (props: TextInputType) => {
  const {
    className,
    id,
    name,
    defaultValue,
    placeholder,
    type,
    forwardRef,
    ariaInvalid,
    ariaDescribedBy,
    style = {},
    onChange
  } = props;

  return (
    <StyledInput
      className={className}
      id={id}
      name={name}
      defaultValue={defaultValue}
      placeholder={placeholder}
      type={type}
      ref={forwardRef}
      aria-invalid={ariaInvalid}
      aria-describedby={ariaDescribedBy}
      style={style}
      onChange={onChange}
    />
  );
};
