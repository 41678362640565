import React from 'react';
import styled, { keyframes } from 'styled-components';

const grow = keyframes`
  0% {
    width: 20px;
    height: 20px;
  }

  50% {
    width: 24px;
    height: 24px;
  }

  100% {
    width: 20px;
    height: 20px;
  }
`;

const StyledContainer = styled.div`
  zindex: 1000;
  background-color: transparent;
  width: 100%;
  min-height: 200px;
  max-height: 450px;
  display: grid;
  placecontent: center;
`;

const StyledWrapper = styled.div`
  display: grid;
  width: 120px;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
`;

const StyledSquareOne = styled.div`
  animation: ${grow} 1s ease 0s infinite;
  background: #f25757;
  height: 20px;
  width: 20px;
`;

const StyledSquareTwo = styled.div`
  animation: ${grow} 1s ease 0s infinite;
  background: #0e60ff;
  animation-delay: 0.2s;
  height: 20px;
  width: 20px;
`;
const StyledSquareThree = styled.div`
  animation: ${grow} 1s ease 0s infinite;
  background: #a3cef1;
  animation-delay: 0.3s;
  height: 20px;
  width: 20px;
`;

export const Loader = (props: any) => {
  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledSquareOne />
        <StyledSquareTwo />
        <StyledSquareThree />
      </StyledWrapper>
    </StyledContainer>
  );
};
