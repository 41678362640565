import React from 'react';
import styled from 'styled-components';

type InputLabelPropsType = {
  children: JSX.Element | JSX.Element[] | string[] | string;
  className?: string;
  htmlFor?: string;
  theme?: {};
};

const StyledInputLabel = styled.label`
  font-family: ${(props) => props.theme.fonts.raleway};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${(props) => props.theme.fontSizes.xs};
`;

export const InputLabel = (props: InputLabelPropsType) => {
  const { className, children, htmlFor } = props;
  return (
    <StyledInputLabel htmlFor={htmlFor} className={className}>
      {children}
    </StyledInputLabel>
  );
};
