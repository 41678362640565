import React from 'react';
import styled from 'styled-components';

const StyledForm = styled.form``;

type TextInputType = {
  children: JSX.Element | JSX.Element[] | string;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
};

export const Form = ({ children, onSubmit }: TextInputType) => {
  return <StyledForm onSubmit={onSubmit}>{children}</StyledForm>;
};
