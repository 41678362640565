import React from 'react';
import styled from 'styled-components';
import { Button as ZDGButton } from '@zendeskgarden/react-buttons';

type ButtonPropsType = {
  children: JSX.Element | string[] | string;
  className?: string;
  theme?: {};
  isDanger?: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  size?: 'small' | 'medium' | 'large';
  type?: 'button' | 'submit' | 'reset' | undefined;
  /** Stretch the button to its container width */
  isStretched?: boolean;
  /** Applies primary button styling */
  isPrimary?: boolean;
  /** Applies basic button styling */
  isBasic?: boolean;
  /** Applies link (anchor) button styling */
  isLink?: boolean;
  /** Applies pill styling */
  isPill?: boolean;
  /** Applies inset `box-shadow` styling on focus */
  focusInset?: boolean;
  disabled?: boolean | null | undefined;
};

type BaseButtonPropsType = {
  children: JSX.Element | string[] | string;
  className?: string;
  isDanger?: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  size?: 'small' | 'medium' | 'large';
  type?: 'button' | 'submit' | 'reset' | undefined;
  /** Stretch the button to its container width */
  isStretched?: boolean;
  /** Applies primary button styling */
  isPrimary?: boolean;
  /** Applies basic button styling */
  isBasic?: boolean;
  /** Applies link (anchor) button styling */
  isLink?: boolean;
  /** Applies pill styling */
  isPill?: boolean;
  /** Applies inset `box-shadow` styling on focus */
  focusInset?: boolean;
  disabled?: boolean | null | undefined;
};

const StyledZDGButton = styled(ZDGButton)`
  font-family: ${(props) => props.theme.fonts.raleway};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${(props) => props.theme.fontSizes.xs};
  height: auto;
  border-width: 5px;
  border-radius: 0px;
  display: block;
`;

const BaseButton = ({
  className,
  onClick,
  type,
  isPrimary,
  isDanger,
  isPill,
  isBasic,
  focusInset,
  isLink,
  isStretched,
  disabled,
  size,
  children
}: BaseButtonPropsType) => {
  return (
    <StyledZDGButton
      className={className}
      type={type}
      onClick={onClick}
      isPrimary={isPrimary}
      isDanger={isDanger}
      isPill={isPill}
      isBasic={isBasic}
      focusInset={focusInset}
      isLink={isLink}
      isStretched={isStretched}
      disabled={disabled as any}
      size={size}
    >
      {children}
    </StyledZDGButton>
  );
};

export const Button = ({
  className,
  onClick,
  type,
  isPrimary,
  isDanger,
  isPill,
  isBasic,
  focusInset,
  isLink,
  isStretched,
  disabled,
  size,
  children
}: ButtonPropsType) => {
  return (
    <BaseButton
      className={className}
      type={type}
      onClick={onClick}
      isPrimary={isPrimary}
      isDanger={isDanger}
      isPill={isPill}
      isBasic={isBasic}
      focusInset={focusInset}
      isLink={isLink}
      isStretched={isStretched}
      disabled={disabled}
      size={size}
    >
      {children}
    </BaseButton>
  );
};
