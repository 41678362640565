const ReactGA = require('react-ga');

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || 'UA-XXXXX-X';

const initGA = () => {
  ReactGA.initialize(trackingId);
};

const gaTrackPage = () => {
  ReactGA.set({ page: window.location.pathname, title: document.title });
  ReactGA.pageview(window.location.pathname);
};

const gaSetData = (data: any) => {
  ReactGA.set(data);
};

const gaTrackEvent = (data: any) => {
  ReactGA.event(data);
};

export const EVENT_CATEGORIES = {
  DOWNLOAD_ALBUM: 'DOWNLOAD_ALBUM'
};

export const EVENT_ACTIONS = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
};

export const GA = {
  initGA,
  gaTrackPage,
  gaSetData,
  gaTrackEvent
};
