import React from 'react';
import styled from 'styled-components';

type HorizontalBreakPropsType = {
  className?: string;
  theme?: {};
  backgroundColor?: string;
  margin?: string;
};

interface HorizontalBreakStyleProps {
  backgroundColor?: string;
  margin?: string;
}

const StyledHorizontalBreak = styled.div`
  height: 15px;
  margin: ${(props: HorizontalBreakStyleProps) => props.margin || '10px 0'};
  background-color: ${(props: HorizontalBreakStyleProps) =>
    props.backgroundColor || '#424242'};
`;

export const HorizontalBreak = (props: HorizontalBreakPropsType) => {
  const { className, backgroundColor, margin } = props;
  return (
    <StyledHorizontalBreak
      backgroundColor={backgroundColor}
      margin={margin}
      className={className}
    />
  );
};
