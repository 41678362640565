import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Header, HeaderItemWrapper, HeaderItemText } from '@zendeskgarden/react-chrome';
import styled from 'styled-components';

import { H1 } from '../components/H1';
import { P1 } from '../components/P1';
import { HorizontalBreak } from '../components/HorizontalBreak';
import { featureFlags } from '../utils/features';
import KubbyDump from '../assets/home/kubby_dump.svg';

import KubbyKids from '../assets/home/kids.svg';
import KubbyArtist from '../assets/home/artist.svg';
import KubbyFooter from '../assets/footerArt.svg';

const StyledGridOne = styled(Grid)`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 50px;
    padding-right: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0 10px;
    margin-top: 110px;
  }
`;

const StyledGridTwo = styled(Grid)`
  min-height: 800px;
  margin-top: 70px;
`;

const StyledGridThree = styled(Grid)`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 50px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 10px;
  }
`;

const StyledButtonLink = styled(Link)`
  margin: 10px 0;
  height: auto;
  width: max-content;
  display: block;
  padding: 10px 60px;
  border: 5px solid;
  text-decoration: none;
  color: #f25757;
  font-weight: ${(props) => props.theme.fontWeights.extrabold};
  font-family: ${(props) => props.theme.fonts.roboto};
  font-size: 18px;
  border-color: #f25757;
`;

const StyledH1 = styled(H1)`
  font-weight: ${(props) => props.theme.fontWeights.extrabold};
`;

const StyledP1 = styled(P1)`
  font-size: 24px;
`;

const StyledKubbyDumpImg = styled.img`
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const StyledKubbyKidsImg = styled.img`
  display: block;
  margin-top: 40px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: 400px;
  }
`;

const StyledKubbyArtistImg = styled.img`
  display: block;
  margin-top: 50px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: 400px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const StyledKubbyFooter = styled.img`
  width: 100%;
  margin: -3px;
`;

const Home: React.FC = (props: any) => {
  const { history } = props;

  return (
    <>
      <StyledGridOne>
        <Row>
          <Col md={7}>
            <StyledGridTwo>
              <StyledH1>All your content with one link</StyledH1>
              <HorizontalBreak backgroundColor="#f25757" />
              <StyledP1>
                Kubby provides one link for all your links. Give your audiences a single
                clean url to access everything you have to offer. Your entire online
                presence in one spot.{' '}
              </StyledP1>
              {featureFlags.requestAccess ? (
                <StyledButtonLink to={'/request-access'}>
                  Sign Up For Our Free Beta
                </StyledButtonLink>
              ) : (
                <StyledButtonLink to={'/sign-up'}>
                  Create A Free Account Now
                </StyledButtonLink>
              )}
              <StyledKubbyKidsImg src={KubbyKids} alt="Kubby Kids" />
            </StyledGridTwo>
            <StyledGridTwo>
              <StyledH1>Your personalized brand</StyledH1>
              <HorizontalBreak backgroundColor="#a3cef1" />
              <StyledP1>
                Take control of your brand and content. Unleash your inner artist and
                customize the look and feel of your Kubby. Connect with your audiences.{' '}
              </StyledP1>
              <StyledKubbyArtistImg src={KubbyArtist} alt="Kubby Artist" />
            </StyledGridTwo>
          </Col>
          <Col md={5}>
            <StyledKubbyDumpImg src={KubbyDump} alt="Kubby Dump" />
          </Col>
        </Row>
      </StyledGridOne>
      <StyledGridThree>
        <Row alignItems={'center'}>
          <Col md={7}>
            <StyledH1>Costs less than a latte</StyledH1>
            <HorizontalBreak backgroundColor="#f0bcd4" />
            <StyledP1>
              Your first 30 days are on us. After that Kubby only costs $5 a month,
              cheaper than your cheat day drink from your favorite cafe.
            </StyledP1>
          </Col>
        </Row>
      </StyledGridThree>
      <StyledKubbyFooter src={KubbyFooter} alt="Kubby Footer" />
      <HorizontalBreak backgroundColor="#f25757" margin="-3" />
    </>
  );
};

export default Home;
