import React, { useContext, useState } from 'react';
import axios from 'axios';

import { Grid, Row, Col } from '@zendeskgarden/react-grid';

import styled from 'styled-components';

import { Button } from '../components/Button';

import { H2 } from '../components/H2';
import { H4 } from '../components/H4';
import { InputField } from '../components/InputField';
import { InputLabel } from '../components/InputLabel';
import { P2 } from '../components/P2';
import { Form } from '../components/Form';
import { Loader } from '../components/Loader';
import { HorizontalBreak } from '../components/HorizontalBreak';

import KubbyDevs from '../assets/requestAccess/devs.svg';

const StyleLabel = styled.span`
  margin-bottom: 5px;
  display: block;
`;

const StyledGrid = styled(Grid)`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 50px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 20px;
    margin-top: 150px;
  }
`;

const StyledDevImg = styled.img`
  width: 100%;
`;

const config = {
  cors: process.env.REACT_APP_CORS_PROXY,
  formUrl: process.env.REACT_APP_GOOGLE_FORM
};

const Input = ({ name, label, placeholder, doChange, type = 'text' }: any) => {
  return (
    <InputLabel htmlFor={name}>
      <StyleLabel>{label}</StyleLabel>
      <InputField
        name={name}
        placeholder={placeholder}
        type={type}
        id={name}
        onChange={doChange}
      />
    </InputLabel>
  );
};

const RequestAccess: React.FC = (props: any) => {
  const initialState: Record<string, any> = {
    name: { id: 288802025, value: '' },
    email: { id: 1610782925, value: '' },
    phone: { id: 1810021638, value: '' }
  };

  const [inputs, setInputs] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const formData = new FormData();

    for (const [_, item] of Object.entries(inputs)) {
      formData.append(`entry.${item.id}`, item.value);
    }

    const { data, status } = await axios({
      url: `${config.cors}${config.formUrl}`,
      method: 'post',
      data: formData,
      responseType: 'json'
    });

    setLoading(true);

    if (status === 200) {
      setLoading(false);
      setSuccessful(true);
    }
  };

  const handleChange = (e: any) => {
    const { value, name }: { value: string; name: string } = e.target;

    inputs[name].value = value;

    setInputs(inputs);
  };

  return (
    <StyledGrid columns={'12'}>
      <Row alignItems={'center'} justifyContent={'center'}>
        <Col sm={6} md={8} lg={6} xl={5}>
          <H2>Get early access to Kubby!</H2>
          <HorizontalBreak backgroundColor="#F25757" margin="20px 0" />
          <P2>
            Kubby wants to be the one link for all your content. We are currently building
            the best possible product and would love to hear from you. Give us a bit of
            information about yourself and we will reach out.
          </P2>
          <br />
          {loading ? (
            <Loader />
          ) : !successful ? (
            <Form onSubmit={handleSubmit}>
              <Input
                name="name"
                label="Name"
                placeholder="Your Name"
                doChange={handleChange}
              />
              <Input
                name="email"
                label="Email"
                placeholder="Your Email"
                doChange={handleChange}
                type="email"
              />
              <Input
                name="phone"
                label="Phone Number"
                placeholder="Your Phone Number"
                doChange={handleChange}
              />
              <br />
              <br />
              <Button type="submit">Submit Your Information</Button>
            </Form>
          ) : (
            <>
              <H4>🙌 🙌 🙌 Thanks for signing up! 🙌 🙌 🙌 </H4>
              <P2>Someone from the Kubby team will reach out shortly.</P2>
            </>
          )}
          <br />
          <P2>Thank you from the devs!</P2>
          <StyledDevImg src={KubbyDevs} alt="Kubby Artist" />
        </Col>
      </Row>
    </StyledGrid>
  );
};

export default RequestAccess;
