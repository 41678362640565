import React from 'react';
import { ThemeProvider } from '@zendeskgarden/react-theming';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import { Body as ZDBody } from '@zendeskgarden/react-chrome';
import { HelmetProvider } from 'react-helmet-async';
import styled from 'styled-components';

import { theme } from './styles/theme';
import { AuthProvider } from './contexts/AuthContext';
import Routes from './Routes';

// const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
//   ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
//   : '';
// const stripePromise = loadStripe(stripePublishableKey);

const Body = styled(ZDBody)`
  background-color: ${(props) => props.theme.colors.background};
  height: 100vh;
`;

const App: React.FC = (props: any) => {
  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <AuthProvider>
          <Body>
            <Routes />
          </Body>
        </AuthProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default App;
