import React, { useReducer, useEffect } from 'react';

interface IUser {
  isAuthenticated: boolean;
  user: object;
}

const initialState: IUser = {
  isAuthenticated: false,
  user: {}
};

type Action =
  | { type: 'SIGN-UP'; payload: Partial<IUser> | any }
  | { type: 'LOGIN'; payload: Partial<IUser> | any }
  | { type: 'LOGOUT'; payload: Partial<IUser> | any };

const reducer = (state: IUser = initialState, action: Action) => {
  if (action === null) {
    localStorage.removeItem('state');
    return initialState;
  }

  switch (action.type) {
    case 'LOGIN': {
      localStorage.setItem('state', JSON.stringify(state));
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
        isAuthenticated: true
      };
    }
    case 'LOGOUT': {
      localStorage.removeItem('state');
      return {
        ...state,
        user: {},
        isAuthenticated: false
      };
    }
    case 'SIGN-UP': {
      localStorage.setItem('state', JSON.stringify(state));
      return {
        ...state,
        user: { ...state.user, ...action.payload.user }
      };
    }
    default:
      return state;
  }
};

const localUserState = JSON.parse(localStorage.getItem('state') as any);

export const AuthContext = React.createContext({} as any);

export function AuthProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, localUserState || initialState);

  useEffect(() => {
    localStorage.setItem('state', JSON.stringify(state));
  }, [state]);

  const value = { state, dispatch };
  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}
